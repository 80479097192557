var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBL0000289", height: "415px" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          deptValue: "radiationResponsibleDeptCd",
                          type: "dept_user",
                          label: "LBL0000290",
                          beforeText: "",
                          name: "radiationResponsibleUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.radiationResponsibleUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "radiationResponsibleUserId",
                              $$v
                            )
                          },
                          expression: "supWork.radiationResponsibleUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" },
          [
            _c("c-table", {
              attrs: {
                title: "LBL0000232",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
              on: { "table-data-change": _vm.tableDataChange },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }